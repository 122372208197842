
import Vue from "vue";
import PdfJg from "@/components/Download-pdf.vue";
export default Vue.extend({
  components: {
    PdfJg,
  },
  data: () => ({
    items: require("@/data/crash/bespokecrash.json"),
    crashItems: require("@/data/crash/crashoptions.json"),
    crashItems2: require("@/data/crash/crashoptions2.json"),
  }),
});
