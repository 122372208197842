import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg"},[_c('div',{staticClass:"purple-section"},[_vm._m(0),_c(VContainer,{attrs:{"id":"max-width"}},[_c('hr',{staticClass:"section-jg--title"}),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},_vm._l((_vm.items),function(project,idx){return _c(VCol,{key:idx,staticClass:"align-center",attrs:{"xs":"6","sm":"6","md":"4","lg":"3"}},[_c('a',{attrs:{"href":project.href}},[(project.hasChip)?_c(VChip,{staticClass:"chip-card",attrs:{"color":"primary","overlap":""}},[_c('span',[_vm._v(_vm._s(project.chip || ""))])]):_vm._e(),_c('img',{staticClass:"image-game",class:project.class,attrs:{"src":require(`@/assets/${project.img}`)}})],1)])}),1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg--title",attrs:{"id":"Everi"}},[_c('h2',[_vm._v("EVERI")])])
}]

export { render, staticRenderFns }