
import Vue from "vue";
import JgBespokeCrash from "./JgBespokeCrash.vue";
import JgBespokeDouble from "./JgBespokeDouble.vue";
import JgBespokeMines from "./JgBespokeMines.vue";
import JgBespokeBoom from "./JgBespokeBoom.vue";
import JgCatalogGames from "./JgCatalogGames.vue";
import JgBespokeEveri from "./JgBespokeEveri.vue";
import JgBespokeNovibet from "./JgBespokeNovibet.vue";

export default Vue.extend({
  components: {
    // VideoGrid,
    // DownloadPdfVue,
    // VideoGridMotograu,
    JgBespokeCrash,
    JgBespokeDouble,
    JgBespokeMines,
    JgCatalogGames,
    JgBespokeBoom,
    JgBespokeEveri,
    JgBespokeNovibet,
  },
  name: "Catalog",
  data: () => ({
    items: require("@/data/catalog.json"),
  }),
  methods: {
    getLink(project: any) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      return isMobile
        ? project.href.replace("&v=Mobile", "&v=Mobile&isp=1")
        : project.href.replace("&v=Mobile", "&v=Desktop");
    },
  },
  
});
