<template>
  <v-container style="width: 100%; margin: 0 auto; padding: 0">
    <!-- <v-row>
      <v-col cols="12" sm="6">
        <div class="video">
          <video
            style="border-radius: 12px; margin-bottom: -10px"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="require('@/assets/video/Jogo-Crash.mp4')"
              type="video/mp4"
            />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="video">
          <video
            style="border-radius: 12px; margin-bottom: -10px"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="require('@/assets/video/Jogo-Double.mp4')"
              type="video/mp4"
            />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span>Mines </span>
        <div class="video">
          <video
            style="border-radius: 12px; margin-bottom: -10px"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="require('@/assets/video/Mines-EDS.mp4')"
              type="video/mp4"
            />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </v-col> 
    </v-row> -->
    <!-- <v-row align="center">
      <v-col xs="6" sm="6" md="6" lg="6">
        <span>Crash </span>
        <div class="video">
          <video
            style="border-radius: 12px; margin-bottom: -10px"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="require('@/assets/video/Jogo-Crash.mp4')"
              type="video/mp4"
            />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </v-col>
      <v-col xs="6" sm="6" md="6" lg="6">
        <span>Double</span>

        <div class="video">
          <video
            style="border-radius: 12px; margin-bottom: -10px"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="require('@/assets/video/Jogo-Double.mp4')"
              type="video/mp4"
            />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "VideoGrid",
};
</script>
<style scoped lang="scss">
.video {
  width: 382px;
  height: 226px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 141.04px;
    height: 83.443px;
  }
}

.motograu {
  text-align: center;
  font-size: 16px;
  background-color: #f5f5f5;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
</style>
